import './App.css';
import React,{useState} from 'react';

function App() {
  const [day,setDay] =useState( [
    {id:1,d:'Monday',s:1},
    {id:2,d:'Tuesday',s:0},
    {id:3,d:'Wednesday',s:0},
    {id:4,d:'Thursday',s:0},
    {id:5,d:'Friday',s:0},
    {id:6,d:'Saturday',s:0},
    {id:7,d:'Sunday',s:0}
  ])

  
  function shuffle(arr) {
    const newarr = arr.sort(() => Math.random() - 0.5);
    console.log('This is the new random array' + newarr)
    return newarr;
  }

  const handleClick=()=>{
    setDay(shuffle(day));

  }

  

  return (
    <div className="App">
      <div className='daycontainer'>
        
        {day.map((da) => (
          <div className='weekdaybtn' onClick={()=>da.s===0 ? setDay(1):setDay(0)}>
            {da.s === 0 ? da.id:da.d}</div>
        ))}
      </div>
        <button onClick={handleClick}>Randomise</button>
    </div>
  );
}

export default App;
